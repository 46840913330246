
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import ApiService from '@/core/services/ApiService';
import { Actions } from '@/store/enums/StoreEnums';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { hideModal } from '@/core/helpers/dom';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import AddTagModal from '@/components/modals/AddTagModal.vue';
import AddSubModelModal from '@/components/modals/AddSubModelModal.vue';

export default {
  name: 'Edit Car',
  components: {
    ConfirmModal,
    AddTagModal,
    AddSubModelModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const subModels = ref<any>([]);
    const tags = ref([]);
    const formData = ref<any>({});
    const loading = ref(false);
    const modalRef = ref<any>(null);
    const { id } = route.params;
    const models = ref<any>([]);
    const subModelModel = { subModels, models };

    onMounted(async () => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

      if (id) {
        const { data }: any = await ApiService.get(`car-year/${id}`);
        const carYear = data.data;

        formData.value = {
          subModel: carYear.serie,
          tags: carYear.tags,
          year: carYear?.name || '-',
          seat: carYear.seat,
          door: carYear.door,
          transmission: carYear.transmission,
        };
      }

      const subModel = await ApiService.get('car-brand-series');
      const model = await ApiService.get('car-brand-model');
      const tag = await ApiService.get('car-tag');

      subModels.value = subModel.data.datas;
      tags.value = tag.data.datas;
      models.value = model.data.datas;

      const series = subModels.value.find(
        (sub) => sub.id === formData.value.subModel
      );
      const title = formData.value.subModel
        ? `Edit ${series?.name || '-'}`
        : 'Edit Car';
      document.title = title;

      setCurrentPageBreadcrumbs(
        'Car & Function',
        [{ name: 'Car Lists', path: '/car-function/car-lists' }, 'Edit Car'],
        {}
      );

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
    });

    const submit = async () => {
      loading.value = true;
      const data: any = {
        name: formData.value.year,
        seat: formData.value.seat,
        door: formData.value.door,
        transmission: formData.value.transmission,
        serie: formData.value.subModel,
        tags: formData.value.tags,
      };

      await ApiService.patch(`car-year/${id}`, data);

      loading.value = false;
      hideModal(modalRef.value.$el);

      await Swal.fire({
        text: 'Car has been successfully edited.',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Go to car lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      router.push({ name: 'Car Lists' });
    };

    const limitMaxNumber = (val, num) => {
      if (formData.value[val] > num) {
        formData.value[val] = num;
      }
    };

    return {
      subModels,
      tags,
      formData,
      submit,
      loading,
      limitMaxNumber,
      modalRef,
      subModelModel,
    };
  },
};
